import styled from 'styled-components';
import React from 'react';
import Address from './Address';
import ContactForm from './ContactForm';

const StyledSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding-top: 10rem;

  @media screen and (min-width: 1024px) {
    flex-flow: row wrap;
    align-items: flex-end;
    height: 100vh;
  }
`;

const Footer = styled.div`
  justify-self: flex-end;
  margin-top: 1rem;
  text-align: center;

  p {
    font-size: 0.8rem;
    padding: 0.7rem 0;
  }

  @media screen and (min-width: 768px) {
    p {
      font-size: 1.4rem;
      padding: 1rem 0;
    }
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 100%;
    align-self: flex-end;

    p {
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1680px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

const Divider = styled.hr`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export interface Props {
  phonesUae: string[];
  phonesEgypt: string[];
  mail: string;
}
const Contact: React.FC<Props> = (props) => {
  const date = new Date();

  return (
    <>
      <StyledSection id="contactus">
        <ContactForm />
        <Address data={{ ...props }} />
        <Footer>
          <Divider />
          <p>
            <span>© Copyrights </span>
            <span>{date.getFullYear()}</span>
            <span>, All rights Reserved</span>
          </p>
        </Footer>
      </StyledSection>
    </>
  );
};

export default Contact;
