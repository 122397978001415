import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import config from 'react-reveal/globals';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useWindowSize } from 'react-use';
import { FluidObject } from 'gatsby-image';
import { Layout, Navbar, SocilaIcons, BgVideo } from '../components/common';
import { Landing, About, Products, Contact } from '../components/sections';
import BackgroundVideo from '../videos/bg-video.mp4';
import Fullpage from '../components/common/Fullpage';

config({ ssrFadeout: false });

const Wrapper = styled.main`
  margin: 0 1.2rem;

  @media screen and (min-width: 768px) {
    margin: 0 3rem;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 6rem;
  }
`;

interface Props {
  data: {
    logo: { childImageSharp: { fluid: FluidObject } };
    home: { frontmatter: { homeTitle: string } };
    courses: {
      frontmatter: {
        coursesText: string;
        courses: {
          courseTitle: string;
          courseImage: { childImageSharp: { fluid: FluidObject } };
          desktop: { childImageSharp: { fluid: FluidObject } };
          tablet: { childImageSharp: { fluid: FluidObject } };
          mobile: { childImageSharp: { fluid: FluidObject } };
          subjects: { subject: string }[];
        }[];
      };
    };
    about: {
      frontmatter: {
        aboutText: string;
        aboutCards: { heading: string; text: string }[];
      };
    };
    contacts: {
      frontmatter: {
        phoneNumbers: {
          uae: { phone1: string; phone2: string };
          egypt: { phone1: string; phone2: string };
        };
        contactMail: string;
      };
    };
    poster: {
      childImageSharp: { fluid: FluidObject };
    };
  };
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const logo = data.logo.childImageSharp.fluid;
  const { homeTitle: title } = data.home.frontmatter;
  const { coursesText, courses } = data.courses.frontmatter;
  const { aboutText, aboutCards } = data.about.frontmatter;
  const { phoneNumbers, contactMail } = data.contacts.frontmatter;
  const LandingData = { title };
  const url = 'training-schedule.pdf';
  const { fluid: poster } = data.poster.childImageSharp;
  const aboutData = {
    subtitle: aboutText,
    cardsData: aboutCards,
  };

  const productsData = {
    title: 'What we offer ?',
    subtitle: coursesText,
    file: url,
    products: courses,
  };

  const contactData = {
    phonesUae: [phoneNumbers.uae.phone1, phoneNumbers.uae.phone2],
    phonesEgypt: [phoneNumbers.egypt.phone1, phoneNumbers.egypt.phone2],
    mail: contactMail,
  };

  const { width } = useWindowSize();

  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta
            name="Description"
            content="We provide world-class trainings and consultancy services"
          />
          <title>Missions-TC</title>
          <link rel="canonical" href="https://www.missions-tc.com" />
        </Helmet>
      </HelmetProvider>
      <BgVideo video={BackgroundVideo as string} poster={poster} />
      <Navbar image={logo} />
      <SocilaIcons />
      <Wrapper>
        {width >= 1024 ? (
          <>
            <Fullpage
              landingData={LandingData}
              productsData={productsData}
              aboutData={aboutData}
              contactData={contactData}
            />
          </>
        ) : (
          <>
            <Landing title={title} />
            <Products
              title="What we offer ?"
              subtitle={coursesText}
              file={url}
              products={courses}
            />
            <About subtitle={aboutText} cardsData={aboutData.cardsData} />
            <Contact
              phonesUae={contactData.phonesUae}
              phonesEgypt={contactData.phonesEgypt}
              mail={contactMail}
            />
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    home: markdownRemark(wordCount: {}, frontmatter: { page: { eq: "home" } }) {
      frontmatter {
        homeTitle
      }
    }
    about: markdownRemark(frontmatter: { page: { eq: "about" } }) {
      frontmatter {
        aboutText
        aboutCards: cards {
          heading
          text
        }
      }
    }
    courses: markdownRemark(frontmatter: { page: { eq: "courses" } }) {
      frontmatter {
        coursesText
        courses {
          courseTitle
          courseSlug
          subjects {
            subject
          }
          courseImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktop: courseImage {
            childImageSharp {
              fluid(maxWidth: 192, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tablet: courseImage {
            childImageSharp {
              fluid(maxWidth: 640, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mobile: courseImage {
            childImageSharp {
              fluid(maxWidth: 200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    contacts: markdownRemark(frontmatter: { page: { eq: "contacts" } }) {
      frontmatter {
        phoneNumbers {
          egypt {
            phone1
            phone2
          }
          uae {
            phone1
            phone2
          }
        }
        contactMail
      }
    }

    logo: file(relativePath: { eq: "missions_logo_white.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster: file(relativePath: { eq: "bgposter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 25) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
