import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaPhone, FaRegEnvelope } from 'react-icons/fa';

const StyledAddress = styled.article`
  flex: 0 0 100%;

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
  }
`;
const StyledDiv = styled.div`
  display: flex;

  svg {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    svg {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 1024px) {
    svg {
      font-size: 1.3rem;
    }
  }

  @media screen and (min-width: 1280px) {
    svg {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 1680px) {
    svg {
      font-size: 1.9rem;
    }
  }
`;

const Col = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 49.5%;
  flex-flow: column nowrap;

  h3 {
    font-size: 1rem;
    font-weight: normal;
    margin: 0.9rem 0;
  }

  div {
    margin-top: 1rem;

    svg {
      font-size: 0.7rem;
    }

    a {
      color: white;
      display: inline-block;
      font-size: 0.8rem;
      margin-left: 0.1rem;
    }
  }

  @media screen and (min-width: 768px) {
    h3 {
      font-size: 1.7rem;
    }

    div {
      margin-top: 0.5rem;

      svg {
        font-size: 1rem;
      }

      a {
        font-size: 1.4rem;
        margin-left: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 3.4rem 0;

    h3 {
      font-size: 1rem;
    }

    div {
      svg {
        font-size: 0.7rem;
      }

      a {
        font-size: 0.8rem;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 3.4rem 0;

    h3 {
      font-size: 1rem;
    }

    div {
      margin: 1rem 0;

      svg {
        font-size: 0.7rem;
      }

      a {
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (min-width: 1680px) {
    padding: 3.1rem 0;

    h3 {
      font-size: 1.3rem;
    }

    div {
      svg {
        font-size: 0.9rem;
      }

      a {
        font-size: 1.1rem;
      }
    }
  }
`;

const EmailWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  margin: 1.3rem 0;

  a {
    color: white;
    letter-spacing: 0.1rem;
    margin-left: 0.3rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1680px) {
    font-size: 1.2rem;
  }
`;

const Divider = styled.div`
  border-left: 0.1rem solid white;
  flex: 0 0 1%;
`;

interface Props {
  data: {
    phonesUae: string[];
    phonesEgypt: string[];
    mail: string;
  };
}

const Address: React.FC<Props> = ({
  data: { phonesUae, phonesEgypt, mail },
}) => {
  return (
    <StyledAddress>
      <StyledDiv>
        <Col>
          <FaMapMarkerAlt />

          <h3>Dubai - UAE</h3>

          <div>
            <FaPhone />
            <a href={`tel:${phonesUae[0]}`}>{phonesUae[0]}</a>
          </div>
          <div>
            <FaPhone />
            <a href={`tel:${phonesUae[1]}`}>{phonesUae[1]}</a>
          </div>
        </Col>
        <Divider />
        <Col>
          <FaMapMarkerAlt />
          <h3>Cairo - Egypt</h3>
          <div>
            <FaPhone />
            <a href={`tel:${phonesEgypt[0]}`}>{phonesEgypt[0]}</a>
          </div>
          <div>
            <FaPhone />
            <a href={`tel:${phonesEgypt[1]}`}>{phonesEgypt[1]}</a>
          </div>
        </Col>
      </StyledDiv>
      <EmailWrapper>
        <FaRegEnvelope />
        <a href={`mailto:${mail}`} target="_blank" rel="noopener noreferrer">
          {mail}
        </a>
      </EmailWrapper>
    </StyledAddress>
  );
};

export default Address;
